import React from 'react'
import CourseItem from '../../components/CourseItem'
import MainInner from '../../components/MainInner'
import Seo from '../../components/Seo'
import useAllCourses from '../../hooks/useAllCourses'
import { bs } from '../../shevy'
import { mq } from '../../utils'

export default function Courses() {
  const allCourses = useAllCourses()

  return (
    <MainInner>
      <Seo title="Courses" />
      <h2>Courses</h2>
      <ul
        css={{
          display: 'grid',
          alignItems: 'stretch',
          gap: bs(),
          listStyle: 'none',

          [mq.bravo]: {
            gridTemplateColumns: '1fr 1fr',
          },
        }}
      >
        {allCourses.map(course => (
          <CourseItem key={course.id} course={course} />
        ))}
      </ul>
    </MainInner>
  )
}
